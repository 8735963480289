<div
  class="main text-center d-flex flex-column justify-content-center align-items-center"
>
  <div class="main text-center mr-4 ml-4">
    <div class="alert alert-success" role="alert" *ngIf="success.hasSuccess">
      {{ success.message }}
    </div>

    <div class="alert alert-warning" role="alert" *ngIf="error.hasError">
      {{ error.message }}
    </div>

    <!-- <button class="btn btn-primary" (click)="openApp()">Open Unboxed App</button> -->
  </div>
</div>
