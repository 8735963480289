import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  loading;
  verifyToken;
  token;
  routeParams;
  error = {
    hasError: false,
    message: '',
  };
  success = {
    hasSuccess: false,
    message: '',
  };

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    // Route parameters
    this.activatedRoute.params.subscribe((params) => {
      this.routeParams = params;
      this.token = this.routeParams.token;
      console.log('token', this.token);
    });
  }

  resetForm = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8)] ),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });

  ngOnInit(): void {}

  get newPassword() {
    // name property
    return this.resetForm.get('newPassword');
  }

  get confirmPassword() {
    // name property
    return this.resetForm.get('confirmPassword');
  }

  onSubmit() {
    this.loading = true;

    const params = {
      password: this.newPassword.value,
      confirmPassword: this.confirmPassword.value,
      token: this.token,
    };

    // console.log("params", params);

    this.userService.reset(params, this.token).subscribe(
      (result) => {
        // console.log("result", result);
        this.success.hasSuccess = true;
        this.success.message =
          'Your password has been successfully change. Please login using new password.';
      },
      (error) => {
        this.error.hasError = true;
        this.error.message = error.error.message;
      }
    );

    // window.location.href = "http://unboxed-app.com";
  }
}
