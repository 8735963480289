import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  token;
  routeParams;
  loading: boolean;
  error = {
    hasError: false,
    message: '',
  };
  success = {
    hasSuccess: false,
    message: '',
  };

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    // Route parameters
    this.activatedRoute.params.subscribe((params) => {
      this.routeParams = params;
      this.token = this.routeParams.token;
      console.log('token', this.token);
    });
  }

  ngOnInit(): void {
    this.verifyEmail();
  }

  openApp() {
    // window.location.href = "http://unboxed-app.com"
  }

  verifyEmail() {
    this.loading = true;

    this.userService.verify(this.token).subscribe(
      (result) => {
        this.loading = false;

        // console.log("result", result);
        this.success.hasSuccess = true;
        this.success.message =
          'Thank you for verifying your email.';
      },
      (error) => {
        console.log('error', error)
        this.error.hasError = true;
        this.error.message = error.error.message;
      }
    );

    // window.location.href = "http://unboxed-app.com";
  }
}
