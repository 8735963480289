<div class="mt-5 container">

  <p style='text-align:center'><b>TERMS OF USE</b></p>
  <p style='text-align:left'><b>Updated: May 18, 2022</b></p>
  <p>
    <span
      style="
        mso-fareast-font-family: 'Times New Roman';
        color: #0b0b0b;
        background: white;
        mso-highlight: white;
      "
      >These Terms of Use explain the terms for using Unboxed, LLC’s (“Unboxed,”
      “we,” “us,” or “our”) websites, mobile applications (each “Mobile
      Software”), and online services available, accessible, or enabled via the
      websites or Mobile Software (each a “Service” and collectively called the
      “Services”).
    </span>
  </p>

  <p></p>

  <p>
    <span
      style="
        mso-fareast-font-family: 'Times New Roman';
        color: #0b0b0b;
        background: white;
        mso-highlight: white;
      "
      >Please carefully read these Terms of Use (these “Terms”),
      <b style="mso-bidi-font-weight: normal;"
        >including the binding arbitration provision contained below under
        “Dispute Resolution”, which will require you to submit claims you have
        against us to binding and final arbitration, unless you opt out of the
        binding arbitration provision by following the opt-out procedures
        described below under “Dispute Resolution” or unless a limited exception
        described in these Terms applies. If you do not opt out of the binding
        arbitration provision, you will only be permitted to pursue claims
        against us on an individual basis, and not as a plaintiff or class
        member in any class or representative action or proceeding, and you will
        only be permitted to seek relief (including monetary, injunctive, and
        declaratory relief) on an individual basis</b
      >. These Terms govern your access to and use of the Service and, except as
      otherwise provided in these Terms, all Collective Content (defined as
      registered user content and Unboxed content). These Terms constitute a
      legally binding agreement between you and Unboxed.</span
    >
  </p>

  <p></p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >By completing the registration process, browsing Unboxed websites,
      downloading the Mobile Software, or otherwise accessing or using the
      Services, you represent that you have read, understand, and agree to be
      bound by these Terms of Use. If the individual entering into these terms
      of use or otherwise accessing or using the Services is doing so on behalf
      of, or within his or her capacity as a representative, agent, or employee
      of an entity, such individual and such entity agree that: (a) the terms
      “you” and “your” as used herein apply to such entity and, as applicable,
      such individual; and (b) you represent and warrant that the individual
      entering into these Terms of Use has the power, right, authority, and
      capacity to enter into these Terms of Use on behalf of such entity.</span
    >
  </p>

  <p>
    <span
      style="
        mso-fareast-font-family: 'Times New Roman';
        color: #0b0b0b;
        background: white;
        mso-highlight: white;
      "
      >In addition, when using certain parts of the Service, you will be subject
      to additional terms, policies or guidelines applicable to such parts of
      the Service that may be posted on the Site or Apps from time to time,
      including our Privacy Policy, which may be found at <span
        style="mso-spacerun: yes;"
      >
      </span></span
    >
    <a href="https://www.unboxed-app.com/app/privacy-policy"
      >www.unboxed-app.com/app/privacy-policy</a>
      <span
      style="
        mso-fareast-font-family: 'Times New Roman';
        color: #0b0b0b;
        background: white;
        mso-highlight: white;
      "
      >. All such terms, policies and guidelines are incorporated by reference
      into these Terms. Please read all such terms, policies and guidelines
      carefully.</span
    >
  </p>

  <p></p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    USE OF OUR SERVICE
  </h4>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >1.1 <b style="mso-bidi-font-weight: normal;">Eligibility</b>. The
      Services are intended solely for Users who are thirteen (13) years of age
      or older, and any registration, use, or access to the Services by anyone
      under thirteen (13) is strictly prohibited and in violation of this
      Agreement. You may use the Services only if you can form a binding
      contract with Unboxed, and only in compliance with this Agreement and all
      applicable local, state, national, and international laws, rules and
      regulations. If your registration(s) with or ability to access the
      Services, or any other Unboxed community is discontinued by Unboxed due to
      your violation of any portion of the Agreement, you may not access or use
      the Services, and you agree that you will not attempt to re-register with
      or access the Services through use of a different member name or
      otherwise.</span
    >
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 7.1pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >1.2 <b style="mso-bidi-font-weight: normal;">Unboxed Account.</b> In
      order to access certain Services you may be required to register an
      account on the Services (“Account”). Your Account will allow you to access
      the applicable Services and functionality that we may establish and
      maintain from time to time and in our sole discretion. We may implement
      different types of Accounts for different types of Users. You may control
      your User profile and how you interact with the Services by accessing the
      functionality that may be made available on your “Account Settings” page.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 7.1pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >When creating your Account, you must provide accurate and complete
      information and you agree to maintain and promptly update such information
      as necessary to keep it true, accurate, current and complete.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 7.1pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >You are solely responsible for the activity that occurs on your Account,
      and you must keep your Account password secure. You are not allowed to
      provide a third party access to your Account. We encourage you to use
      “strong” passwords (passwords that use a combination of upper and lower
      case letters, numbers and symbols) with your Account.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 7.1pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >You must notify Unboxed immediately of any breach of security or
      unauthorized use of your Account. Unboxed will not be liable for any
      losses caused by any unauthorized use of your Account.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 7.1pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >You may never use another User’s Account without permission.
      Notwithstanding anything to the contrary herein, you acknowledge and agree
      that you shall have no ownership or other property interest in your
      Account, and you further acknowledge and agree that all rights in and to
      your Account are and shall forever be owned by and inure to the benefit of
      Unboxed.</span
    >
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 60pt;
    "
  >
    <span style="background: white; mso-highlight: white;"
      ></span
    >
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >1.3 <b style="mso-bidi-font-weight: normal;">Third Party Accounts</b>. To
      the extent permitted by the function of the Services, you may link your
      Account with or register your Account using a valid account on a social
      networking service or on the app store or marketplace from which you
      downloaded the Mobile Software (e.g. Apple App Store, Google Play store,
      etc.) through which you have connected to the Services (each such account,
      a “Third-Party Account”).
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >By connecting your Third-Party Accounts you are allowing Unboxed, our Insurer or their Designee, and our other partners or affiliates (hereinafter “Data Receivers”) to
      access your Third-Party Account as permitted under the applicable terms
      and conditions that govern your use of each Third-Party Account. You
      represent that you are entitled to disclose your Third-Party Account login
      information to Data Receivers and/or grant Data Receivers access to your Third-Party
      Account (including, but not limited to, for the purposes described herein)
      without breach by you of any of the terms and conditions that govern your
      use of the applicable Third-Party Account and without obligating Data Receivers
      to pay any fees or making Data Receivers subject to any usage limitations imposed
      by such third-party service providers.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >By granting Data Receivers access to any Third-Party Accounts, you understand
      that Data Receivers may access, make available and store (if applicable) any
      information, data, text, software, music, sound, photographs, graphics,
      video, messages, tags and/or other materials accessible through the
      Services that you have provided to and stored in such Third-Party Account
      (“SNS Content”) so that it is available on and through the Services via
      your Account. Unless otherwise specified in the Agreement, all SNS Content
      shall be considered to be your User </span
    >Content (as defined in Section 2.1) for
    <span style="background: white; mso-highlight: white;"
      >all purposes of the Agreement. Depending on the Third-Party Accounts you
      choose to connect to the Services and subject to the privacy settings that
      you have set in such Third-Party Accounts, personally identifiable
      information that you post to your Third-Party Accounts may be available on
      and through your Account.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <span style="background: white; mso-highlight: white;"
      >Please note that if a Third-Party Account or associated service becomes
      unavailable or <span class="SpellE">Data Receivers’</span> access to such
      Third-Party Account is terminated by you or the third-party service
      provider, then your Account and SNS Content may no longer be available on
      and through the Services. You have the ability to disable the connection
      between your Account and your Third-Party Accounts by accessing the
      applicable account settings page on the Third-Party Account.
    </span>
  </p>

  <p
    style="
      margin-top: 8pt;
      margin-right: 2.6pt;
      margin-bottom: 8pt;
      margin-left: 0in;
    "
  >
    <b
      ><span style="background: white; mso-highlight: white;"
        >PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
        PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY
        BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND
        DATA RECEIVERS ARE NOT LIABLE FOR PERSONALLY IDENTIFIABLE INFORMATION THAT MAY
        BE PROVIDED TO IT BY SUCH THIRD-PARTY SERVICE PROVIDERS IN VIOLATION OF
        THE PRIVACY SETTINGS THAT YOU HAVE SET IN SUCH THIRD-PARTY
        ACCOUNTS.</span
      ></b
    ><span style="background: white; mso-highlight: white;">
      Data Receivers make no effort to review any SNS Content for any purpose,
      including but not limited to, for accuracy, legality, or non-infringement,
      and Unboxed is not responsible for any SNS Content.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >1.4 <b style="mso-bidi-font-weight: normal;">Service Rules</b>. You agree
      not to engage in any of the following prohibited activities while
      accessing or using the Services: (a) licensing, sublicensing, selling,
      leasing, transferring, assigning, copying, distributing, disclosing or
      otherwise commercially exploiting any part of the Services in any medium,
      including, without limitation, by providing the Services on a timesharing
      or service bureau basis or using any automated or non-automated “scraping”
      to gather information or content from the Services; (b) framing or
      utilizing framing techniques to enclose any Unboxed Mark or the Services
      (including images, text, page layout or form) of Unboxed except as
      expressly permitted by Unboxed or the function of the Services; (c) taking
      any action that imposes, or may impose, at our sole discretion an
      unreasonable or disproportionately large load on our infrastructure,
      including using any automated system, such as “robots,” “spiders,”
      “offline readers,” etc. to access the Services in a manner that sends more
      request messages to <span class="SpellE">Unboxed’s</span> servers than a
      human can reasonably produce in the same period of time by using a
      conventional on-line web browser (except that Unboxed grants the operators
      of public search engines revocable permission to use spiders to copy
      materials from unboxed-app.com for the sole purpose of and solely to the
      extent necessary for creating publicly available searchable indices of the
      materials but not caches or archives of such materials); (d) modifying,
      translating, adapting, merging, making derivative works of, disassembling,
      decompiling, decoding, reverse compiling, reverse engineering, or “lookup
      table ripping” (or similar) any part of the Services (including without
      limitation, any action by you to access information regarding the
      underlying structure or technical implementation of the Services through
      automated means) except to the extent the foregoing restrictions are
      expressly prohibited by applicable law; (e) transmitting spam, chain
      letters, or other unsolicited email; (f) attempting to interfere with,
      compromise the system integrity or security or decipher any transmissions
      to or from the servers running the Services; (g) impersonating or
      violating the rights of any third party or taking any fraudulent actions;
      (h) uploading invalid data, viruses, worms, or other software agents
      through the Services; (<span class="SpellE">i</span>) collecting or
      harvesting any personally identifiable information, including account
      names, from the Services; (j) using the Services for any commercial
      solicitation purposes; (k) using the Services for the purpose of making
      any products or services that are competitive with the Services; (l)
      interfering with the proper working of the Services; (m) accessing any
      content on the Services through any technology or means other than those
      provided or authorized by the Services; or (n) bypassing the measures we
      may use to prevent or restrict access to the Services, including without
      limitation features that prevent or restrict use or copying of any content
      or enforce limitations on use of the Services or the content
      therein.</span
    ><span
      style="
        mso-fareast-font-family: 'Times New Roman';
        color: #0b0b0b;
        background: white;
        mso-highlight: white;
      "
    ></span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >1.5
      <b style="mso-bidi-font-weight: normal;">Interactions with Other Users</b
      >. You are solely responsible for your interactions with other Users. We
      reserve the right, but have no obligation to monitor disputes between you
      and other Users. You acknowledge and agree that you may be exposed to User
      Content of other Users that is inaccurate, objectionable, or otherwise
      unsuited to your purpose, and you agree that Unboxed will not be liable
      for any harm or damages you allege to incur as a result of any third party
      User Content. You acknowledge and agree that Unboxed will have no
      liability for your interactions with other Users, or for any User’s action
      or inaction.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >1.6
      <b style="mso-bidi-font-weight: normal;">Changes to the Services.</b> We
      may, without prior notice, change, or stop providing the Services or any
      portion thereof; or create usage limits for the Services or any portion
      thereof. We may permanently or temporarily terminate or suspend your
      access to the Services or any portion thereof (including the right to
      cancel any hosted sites) without notice and liability for any reason,
      including if in our sole determination you violate any provision of this
      Agreement, or for no reason.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Upon termination of any portion of the Services for any reason or no
      reason, you continue to be bound by this Agreement until it is
      <span class="GramE">terminated</span> and your Subscriptions will
      auto-renew and remain in effect until cancelled in accordance </span
    >with Section 10. Unboxed
    <span style="background: white; mso-highlight: white;"
      >expressly reserves the right to review every Account for excessive space
      and bandwidth utilization, and to terminate or apply additional fees to
      those Accounts that exceed allowed levels, in our sole discretion.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
   2. USER CONTENT
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.1 <b style="mso-bidi-font-weight: normal;">Generally</b>. Some areas of
      the Services allow Users to post, publish, submit, upload, transmit, or
      otherwise make available on the Services (“Make Available”) content such
      as profile pictures or information, photos, images, music, videos,
      information, comments, questions, messages, works of authorship and other
      content or information (any such materials that a User does Make Available
      is referred to as “User Content”). You retain ownership of your User
      Content.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.2
      <b style="mso-bidi-font-weight: normal;">License Grant to Unboxed</b>. You
      hereby grant Unboxed a royalty-free, sublicensable, transferable,
      non-exclusive, worldwide license to use, reproduce, modify, publish, list
      information regarding, edit, translate, distribute, syndicate, publicly
      perform, publicly display, and make derivative works of all User Content
      that you Make Available, including your or any third-party name, voice,
      and/or likeness as contained in your User Content, in whole or in part,
      and in any form, media or technology, whether now known or hereafter
      developed, in connection with the Services and
      <span class="SpellE">Unboxed’s</span> (and its successors’ and
      affiliates’) business, including without limitation for promoting and
      redistributing part or all of the Services (and derivative works thereof)
      in any media formats and through any media channels.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Notwithstanding anything contained herein to the contrary, by submitting
      your User Content to any forums, comments, or any other area on the
      Services, you hereby expressly permit Unboxed to identify you by your name
      as the contributor of your User Content in any publication in any form,
      media, or technology now known or later developed in connection with your
      User Content.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You hereby waive any moral, droit moral, or other author’s rights you may
      have in connection with any uses of your User Content by Unboxed in
      accordance with the foregoing. Unboxed may exercise the rights to your
      User Content granted under this Agreement without liability for payment of
      any guild fees, residuals, payments, fees, or royalties payable under any
      collective bargaining agreement or otherwise.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.3
      <b style="mso-bidi-font-weight: normal;">License Grant to other Users</b>.
      You also hereby grant each User of the Services a non-exclusive license to
      access your User Content through the Services and to use, reproduce,
      distribute, display, and perform such User Content as permitted through
      the functionality of the Services and under this Agreement.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.4
      <b style="mso-bidi-font-weight: normal;">Deleting Your User Content</b>.
      If the features of the Services allow you to remove or delete User Content
      from the Services, the licenses granted by you in your User Content
      hereunder terminate within a commercially reasonable time after you remove
      or delete such User Content from the Services.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Notwithstanding the foregoing, you understand and agree that Unboxed may
      retain, but not display, distribute, or perform, server copies of User
      Content that have been removed or deleted; provided that in certain cases,
      such as if User Content has been curated by Unboxed for display on
      <span class="SpellE">Unboxed’s</span> Collection, used on
      <span class="SpellE">Unboxed’s</span> social channels, or included in
      printed content, the above licenses granted by you with respect to such
      User Content will not terminate and such User Content may continue to be
      used, displayed, distributed, and performed indefinitely.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.5 <b style="mso-bidi-font-weight: normal;">Restrictions</b>. You agree
      not to Make Available any User Content or take any action using the
      Services that: (a) may create a risk of, glorify, encourage, or threaten
      violence, harm, loss, physical or mental injury, emotional distress,
      death, disability, disfigurement, or self-harm to you or any other person
      or to any animal; (b) may create a risk of, threaten, glorify, or
      encourage any other loss or damage to any person or property; (c) may
      discriminate against, degrade, shame, or harass or encourage, glorify, or
      promote hatred or violence toward any person or any groups of persons; (d)
      contains or depicts sexual acts or sexually explicit or pornographic
      material, including, but not limited to, depictions of children in a
      sexual, sexualized, or nude state; (e) seeks to harm, exploit, or groom
      children by exposing them to inappropriate content or soliciting
      personally identifiable details or otherwise; (f) may constitute,
      contribute to, depict, or encourage a crime, illegal activity, or a
      violation or infringement of any third party’s rights; (g) solicits or
      seeks to obtain or discloses the personal information of any other person;
      (h) you do not have the right to under any law or under contractual or
      fiduciary relationships; (<span class="SpellE">i</span>) deceptively
      impersonates another person or entity or contains information that is
      fraudulent or that you know is not correct and current; or (j) we deem to
      be otherwise unlawful, harmful, abusive, racially or ethnically offensive,
      defamatory, infringing, invasive of personal privacy or publicity rights,
      harassing, humiliating to other people (publicly or otherwise), libelous,
      threatening, profane, or otherwise objectionable or in violation of our
      community guidelines.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.6
      <b style="mso-bidi-font-weight: normal;">Representations and Warranties</b
      >. If you Make Available any User Content, you promise, represent, and
      warrant that you have all necessary rights, consents, waivers, and
      authorizations to grant the rights and licenses in your User Content
      granted under the Agreement and for your User Content to be used in
      accordance with this Agreement and the functionality of the Services
      without causing a violation of the rights of others, including, without
      limitation, all necessary rights, consents, waivers, and authorizations
      under: (a) any performance, mechanical, and sound recording rights in
      musical compositions and sound recordings included in your User Content;
      (b) any rights of privacy or publicity with respect to the name, image,
      and likeness of any individual included in your User Content; and (c) any
      moral, droit moral, or authors rights to works of authorship included in
      your User Content.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.7 <b style="mso-bidi-font-weight: normal;">No Responsibility</b>. Unboxed takes
      no responsibility and assumes no liability for any User Content that you
      or any other User or third party may Make Available. Unboxed is not
      obligated to review or monitor, and does not approve, endorse, or make any
      representations or warranties with respect to User Content. You will be
      solely responsible for your User Content and the consequences arising
      therefrom. You agree that we are only providing a platform for you to Make
      Available your User Content. Unboxed reserves the right, but is not
      obligated to reject and/or remove any User Content that Unboxed believes,
      in its sole discretion, violates this Agreement or is otherwise
      inappropriate for the Services.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.8 <b>No </b><b style="mso-bidi-font-weight: normal;">Storage</b>.
      Unless expressly agreed to by Unboxed in writing elsewhere, Unboxed has no
      obligation to store any of your User Content that you Make Available.
      Unboxed has no responsibility or liability for: (a) the deletion or
      accuracy of any User Content, including your User Content; (b) the failure
      to store, transmit, or receive transmission of User Content; or (c) except
      as described in our <a href="https://www.unboxed-app.com/app/privacy-policy">privacy policy</a>, the
      security, privacy, storage, or transmission of other communications
      originating with or involving use of the Services.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Certain Services may enable you to specify the level at which such
      Services restrict access to your User Content. In such cases, you are
      solely responsible for applying the appropriate level of access to your
      User Content. If you do not choose, the system may default to its most
      permissive setting.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree that Unboxed retains the right to create reasonable limits on
      <span class="SpellE">Unboxed’s</span> use and storage of the User Content,
      including your User Content, such as limits on file size, storage space,
      processing capacity, and similar limits described on the Services or
      otherwise determined by Unboxed in its sole discretion.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.9
      <b style="mso-bidi-font-weight: normal;"
        >No Obligation to Pre-Screen Content</b
      >. You acknowledge that Unboxed has no obligation to pre-screen User
      Content, including, but not limited to, your User Content, but Unboxed
      reserves the right in its sole discretion to pre-screen, refuse, or remove
      any User Content.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >By entering into the Agreement, you hereby provide your irrevocable
      consent to such monitoring. In the event that Unboxed pre-screens,
      refuses, or removes any User Content, you acknowledge that Unboxed does so
      for <span class="SpellE">Unboxed’s</span> benefit, not yours. Without
      limiting the foregoing, Unboxed shall have the right to remove any User
      Content that violates the Agreement or is otherwise objectionable.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >2.10 <b style="mso-bidi-font-weight: normal;">Investigations</b>. Without
      limiting anything to the contrary herein, Unboxed shall have the right,
      but not obligation, in its sole discretion, to investigate potential
      violations of any provision of this Agreement and remove any of your User
      Content for any reason (or no reason), including if such User Content
      violates the Agreement or any applicable law.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
  3. YOUR RIGHTS WITH RESPECT TO THE SERVICES
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >3.1 <b style="mso-bidi-font-weight: normal;">Unboxed Services</b>.
      Subject to the terms and conditions of this Agreement, you are hereby
      granted a non-exclusive, limited, non-transferable, freely revocable
      license to use the Services for your personal or internal use only as
      permitted by the features of the Services.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >3.2 <b style="mso-bidi-font-weight: normal;">Mobile Software</b>. Subject
      to the terms and conditions of this Agreement, Unboxed hereby grants you a
      non-exclusive, non-transferable, revocable license to use a compiled code
      copy of the Mobile Software on mobile devices owned, leased, or controlled
      solely by you, for your personal or internal use. To use the Mobile
      Software you must have a mobile device that is compatible with the Mobile
      Software. You acknowledge that Unboxed may from time to time issue or
      require you to upgrade to an upgraded versions of the Mobile Software. You
      agree that the terms and conditions of this Agreement will apply to all
      such upgrades. The foregoing license grant is not a sale of the Mobile
      Software or any copy thereof, and Unboxed or its third party partners or
      suppliers retain all right, title, and interest in the Mobile Software
      (and all copies thereof).</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >3.3 <b style="mso-bidi-font-weight: normal;">Beta Services</b>. We may make
      available certain Services, including Mobile Software, that allow you to
      access and use certain features, technologies, and/or services that are
      not yet generally commercially released (collectively referred to as the
      “Beta Services”). In addition to the other provisions in this Agreement,
      the following additional provisions in this subsection apply to the Beta
      Services.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >The license granted by Unboxed under this Agreement with respect to the
      Beta Services will automatically terminate upon the release of a generally
      commercially available version of the applicable Beta Services, as
      otherwise set forth in this Agreement, or as determined by Unboxed (“Beta
      Period”). Unboxed may revoke, modify the permitted use of, or suspend your
      access to any Beta Services at any time and for any or no reason.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You acknowledge that the Beta Services, their existence, their features,
      their capacities, their capabilities, their thresholds, their limitations,
      and their mode of operation, any related materials provided by Unboxed,
      any beta test results compiled by you, and any other technical, business,
      product, marketing, and financial information, plans, and data relating to
      the Beta Services are the confidential information of Unboxed (“Beta
      Confidential Information”).
    </span>
  </p>

  <p>
    <a style="mso-comment-reference: A_1;"
      ><span style="background: white; mso-highlight: white;">You</span></a
    ><span style="background: white; mso-highlight: white;">
      agree: (a) to hold the Beta Confidential Information in strict confidence;
      (b) not to disclose any Beta Confidential Information to any third parties
      except as authorized by Unboxed in writing; and (c) not to use any Beta
      Confidential Information for your own use or for any purpose except as
      permitted under this Agreement, including testing the Beta Services and
      providing feedback with respect to such Beta Services to Unboxed.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree to take all practicable measures to protect the secrecy of Beta
      Confidential Information and avoid disclosure or use of Beta Confidential
      Information other than expressly authorized herein, which measures will
      take the form of the highest degree of care that a reasonable person would
      apply to protect his, her, or its own information of a similar nature and
      importance. You agree promptly to notify Unboxed in writing of any misuse
      or misappropriation of Beta Confidential Information that may come to your
      attention. All copies of electronic information and data, or tangible
      items including documents and magnetic media, containing or embodying Beta
      Confidential Information will be permanently destroyed upon the expiration
      or termination of the Beta Period or as otherwise requested by
      Unboxed.</span
    >
  </p>

  <p style="margin-top: 8pt; line-height: 150%;">
    <span style="background: white; mso-highlight: white;"
      ></span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      line-height: 150%;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    4. UNBOXED's PROPRIETARY RIGHTS
  </h4>

  <p style="margin-top: 8pt;">
    <span style="background: white; mso-highlight: white;"
      >4.1 <b style="mso-bidi-font-weight: normal;">Unboxed Property</b>. Except
      for your User Content, the Services and all materials therein or
      transferred thereby, including, without limitation, software, images,
      text, graphics, illustrations, logos, patents, trademarks, service marks,
      copyrights, photographs, audio, videos, music, and User Content belonging
      to other Users (the “Unboxed Property”), and all intellectual property
      rights therein or thereto, are the exclusive property of Unboxed or its
      licensors (including other Users who post User Content to the Service).
    </span>
  </p>

  <p style="margin-top: 8pt;">
    <span style="background: white; mso-highlight: white;"
      >Except as explicitly provided herein, nothing in this Agreement will be
      deemed to create a license in or under any such intellectual property
      rights, and you agree not to sell, license, rent, modify, distribute,
      copy, reproduce, transmit, publicly display, publicly perform, publish,
      adapt, edit or create derivative works from any Unboxed Property. Use of
      Unboxed Property for any purpose not expressly permitted by this Agreement
      is strictly prohibited.
    </span>
  </p>

  <p style="margin-top: 8pt;">
    <span style="background: white; mso-highlight: white;"
      >Unboxed, LLC, Unboxed, and all related graphics, logos, service marks and
      trade names used on or in connection with the Services (“Unboxed Marks”)
      are the trademarks of Unboxed and may not be used without permission in
      connection with your or any third-party products or services.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >4.2 <b style="mso-bidi-font-weight: normal;">Feedback</b>. You may choose
      to or we may invite you to submit feedback, modifications, suggestions,
      improvements, comments, ideas, or the like about Unboxed, the Services, or
      <span class="SpellE">Unboxed’s</span> other products or services,
      including without limitation about how to improve the Services or our
      other products or businesses (“Ideas”). You hereby grant to Unboxed a
      world-wide, royalty free, irrevocable, perpetual license to use,
      incorporate, or otherwise exploit any Ideas in any manner that we see
      fit.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    5. PAID SERVICES
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.1 <b style="mso-bidi-font-weight: normal;">Fees</b>. Certain products
      or services offered on or through the Services may be provided for a fee
      or other charge, including services or features for which access and use
      is purchased on a time limited basis (each a “Subscription”). You agree to
      pay all fees or charges to your Account in accordance with the fees,
      charges, and billing terms in effect at the time a fee or charge is due
      and payable. Our current pricing and payment terms are listed on the
      mobile application store or website of the authorized third party through
      which you have purchased a Subscription (“Distributor”). Unboxed may add
      new products and services for additional fees and charges, or amend fees
      and charges for existing products and services, at any time in its sole
      discretion. Any increased fees for existing Subscriptions will apply
      solely on a forward-looking basis beginning on your next billing
      period.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.2 <b style="mso-bidi-font-weight: normal;">Payment</b>. You may be
      required to provide Unboxed or the Distributor with information pertaining
      to a valid credit card (Visa, MasterCard, or any other issuer accepted by
      us or Distributor), PayPal account, or other permitted payment method
      (“Payment Information”). You agree that Unboxed or the Distributor, as
      applicable, is authorized to immediately charge your Payment Information
      for all fees and charges due and payable for the products and services
      hereunder and that, except as required under applicable law, no additional
      notice or consent is required.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree to immediately notify Unboxed or the applicable Distributor of
      any change in your billing address or Payment Information.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Unboxed reserves the right at any time to change its prices and billing
      methods, either immediately upon posting on the Services or by email
      delivery to you. Without limiting anything set forth therein, you
      acknowledge and agree that we may provide your Payment Information to our
      third party service providers (e.g., our payment processing partner) for
      use pursuant to our
      <a style="color: blue;" href="https://www.unboxed-app.com/app/privacy-policy">privacy policy</a>.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.3 <b style="mso-bidi-font-weight: normal;">Subscription</b>. The fee
      for a Subscription will be billed by the Distributor or Unboxed at the
      start of your Subscription or, if applicable, at the end of any free trial
      period, and at regular intervals in accordance with your elections at the
      time of purchase. The timing of billing may change at any time. Your
      Subscription will continue to automatically renew indefinitely, including
      if you terminate your Account, until your Subscription is canceled in
      accordance with the Agreement. After your initial Subscription period, and
      again after any subsequent Subscription period, your Subscription will
      automatically renew on the first day following the end of such period
      (each a “Renewal Commencement Date”) and continue for an additional
      equivalent period at <span class="SpellE">Unboxed’s</span> then-current
      price for such Subscription.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree that your Account will be subject to this automatic renewal
      feature (even if you otherwise terminate your Account) unless you cancel
      your Subscription prior to the Renewal Commencement Date in accordance
      with any terms between you and the Distributor. You may opt out of the
      auto-renewal of your Subscription, or cancel your Subscription, in the
      manner described at the link <a style="color: blue;" href="https://unboxed-app.com/app/privacy-policy">here</a>. If
      you cancel your Subscription, such cancellation will be effective as of
      the end of your then-current Subscription term (the “Cancellation Date”),
      and your Subscription will not be renewed after such then-current term.
      You will not be eligible for a prorated refund from Unboxed of any portion
      of the fees paid for the then current period of your Subscription. By
      purchasing a Subscription, you authorize the applicable Distributor or
      Unboxed to charge your Payment Information in accordance with your
      selected Subscription plan. Upon renewal of your Subscription, if the
      applicable Distributor or Unboxed does not receive payment via your
      Payment Information, (a) you agree to pay all amounts due on your Account
      upon demand and/or (b) you agree that Distributor or Unboxed may either
      cancel or suspend your Subscription and continue to attempt to charge your
      Payment Information until payment is received.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.4 <b style="mso-bidi-font-weight: normal;">Free Trials</b>. Certain Subscription
      offerings may offer a free trial prior to charging your payment method,
      the period of which will be communicated to you via the Services. If you
      decide to cancel your Subscription before Unboxed starts charging your
      payment method, you must cancel the Subscription before the free trial
      ends. Otherwise, you will be responsible for payment for the full term of
      the Subscription period.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      ><span style="mso-spacerun: yes;"> </span
    ></span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.5 <b style="mso-bidi-font-weight: normal;">No Refunds</b>. UNBOXED DOES NOT
      ISSUE ANY REFUNDS FOR ANY PRODUCTS OR SERVICES PURCHASED ON OR THROUGH THE
      SERVICES AND WE ARE NOT OBLIGATED TO PROVIDE ANY CREDITS FOR PARTIALLY
      USED PERIODS.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.6
      <b style="mso-bidi-font-weight: normal;">Payment Service Provider</b>. All
      financial transactions made in connection with the Services will be
      processed by the Distributor or their third party payment service provider
      in accordance with their respective terms of use, privacy policy, and/or
      any applicable payment terms and conditions. As such, in no event will
      Unboxed be responsible for the actions or inactions of any Distributor or
      their third party payment service provider, including, but not limited to,
      system downtime or payment service outages, and any billing and fee
      disputes may require resolution between you and the Distributor or
      applicable third party. If you notify us of any fee or billing dispute we
      will likely direct you to the Distributor to address such dispute, but, to
      the extent we are able, we may assist you in resolving such dispute.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.7 <b style="mso-bidi-font-weight: normal;">Taxes</b>. The payments
      required under Section 5.1 of this Agreement do not include any Sales Tax
      that may be due in connection with the Services provided under this
      Agreement. If Unboxed determines it has a legal obligation to collect a
      Sales Tax from you in connection with this Agreement, Unboxed shall
      collect such Sales Tax in addition to the payments required under Section
      5.1 of this Agreement.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >If any Services, or payments for any Services, under the Agreement are
      subject to any Sales Tax in any jurisdiction and you have not remitted the
      applicable Sales Tax to Unboxed, you will be responsible for the payment
      of such Sales Tax and any related penalties or interest to the relevant
      tax authority, and you will indemnify Unboxed for any liability or expense
      Unboxed may incur in connection with such Sales Taxes. Upon
      <span class="SpellE">Unboxed’s</span> request, you will provide it with
      official receipts issued by the appropriate taxing authority, or other
      such evidence that you have paid all applicable taxes. For purposes of
      this section, “Sales Tax” shall mean any sales or use tax and any other
      tax measured by sales proceeds that is the functional equivalent of a
      sales tax where the applicable taxing jurisdiction does not otherwise
      impose a sales or use tax.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >5.8
      <b style="mso-bidi-font-weight: normal;"
        >No Liability <span class="GramE">For</span> Third-party Links</b
      >.<span style="mso-spacerun: yes;"> </span>The Services may contain links
      to third-party websites, advertisers, information, materials, products, or
      services (“Third Party Links”) that are not owned or controlled by
      Unboxed. Unboxed does not endorse or assume any responsibility for any
      content or information on or from any Third Party Links. If you access a
      Third Party Link from the Service, you do so at your own risk, and you
      understand that this Agreement and
      <span class="SpellE">Unboxed’s</span> privacy policy do not apply to your
      use of such Third Party Links. You expressly relieve Unboxed from any and
      all liability arising from your use of any Third Party Link, including
      your dealings with or participation in promotions of the applicable third
      party, payments to and delivery of goods from such third party, and any
      other terms (such as warranties) are solely between you and such third
      party. You agree that Unboxed will not be responsible for any loss or
      damage of any sort relating to your dealings with such third
      parties.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    7. INDEMNITY.
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree to defend, indemnify, and hold harmless Unboxed, our Insurer and its Designee, and its
      subsidiaries, agents, licensors, managers, and other affiliated companies
      and their employees, contractors, agents, officers, and directors from and
      against any and all claims, damages, obligations, losses, liabilities,
      costs or debt, and expenses (including but not limited to attorney’s fees)
      arising from: (a) your use of and access to the Services, including any
      data or content, including User Content transmitted or received by you;
      (b) your violation of any term of this Agreement, including without
      limitation your breach of any of the representations and warranties
      herein; (c) any damage or harm or any violation of any third-party right,
      including any right of publicity of privacy or intellectual property right
      caused by you or your User Content; (d) your violation of any applicable
      law, rule or regulation; or (e) any other party’s access and use of the
      Services with your email, password or other appropriate security
      code.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    8. NO WARRANTY.
  </h4>

  <p>
    <b
      ><span style="background: white; mso-highlight: white;"
        >YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
        APPLICABLE LAW, YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, AND THE
        SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL
        FAULTS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNBOXED
        EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        UNBOXED OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
        STATED HEREIN. WITHOUT LIMITING THE FOREGOING, UNBOXED, OUR INSURER, ITS DESIGNEE AND THE AFOREMENTIONED ENTITIES’, OUR PARTNERS, OUR AFFILIATES,
        SUBSIDIARIES, AND LICENSORS DO NOT WARRANT THAT ANY
        PROPERTY, RESULTS FROM THE SERVICE, OR USER CONTENT IS ACCURATE,
        RELIABLE, OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS;
        THAT THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
        UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED;
        OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
        SERVICES IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR
        LOSS OF DATA OR CONTENT THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF
        THE SERVICES.</span
    ></b>
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    9. LIMITATION OF LIABILITY
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >9.1 <b style="mso-bidi-font-weight: normal;">Exclusion of Liability</b>.
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
      UNBOXED, OUR INSURER AND ITS DESIGNEE, ITS PARTNERS, AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
      LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION,
      DAMAGES FOR LOSS OF PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION
      (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME
      OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR USER CONTENT), OR ANY
      THEORY OF LIABILITY.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >9.2 <b style="mso-bidi-font-weight: normal;">Limitation on Damages</b>.
      IN NO EVENT WILL UNBOXED, OUR INSURER OR ITS DESIGNEE, ITS PARTNERS, AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
      SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS,
      LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING
      THE AMOUNT YOU PAID TO UNBOXED HEREUNDER DURING THE 12 MONTHS PRECEDING
      THE EVENTS GIVING RISE TO THE CLAIM OR $100.00, WHICHEVER IS
      GREATER.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >9.3 <b style="mso-bidi-font-weight: normal;">Basis of the Bargain</b>.
      THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
      BASIS OF THE BARGAIN BETWEEN UNBOXED AND YOU. THIS LIMITATION OF LIABILITY
      SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
      NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF UNBOXED HAS BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF
      LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
      APPLICABLE JURISDICTION.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
   10. TERM AND TERMINATION
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >10.1 <b style="mso-bidi-font-weight: normal;">Term</b>. The Agreement
      commences on the earlier of: (a) the date you first used the Services; and
      (b) the date you accepted the Agreement and will remain in full force and
      effect while you use the Services, unless earlier terminated in accordance
      with the Agreement.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >10.2
      <b style="mso-bidi-font-weight: normal;"
        >Termination of Services by Unboxed</b
      >. Unboxed may terminate this Agreement or your ability to access or use
      any or all Services at any time for any reason, including if timely
      payment cannot be charged to your Payment Information for any reason, if
      you have breached any provision of the Agreement, or if Unboxed is
      required to do so by law (e.g., where the provision of the Services are,
      or becomes, unlawful). If you have a Subscription and your Account is
      terminated by us, you must promptly cancel your Subscription in accordance
      with Section 5.3. Otherwise, your Subscription will remain in effect until
      the cancellation of your Subscription in accordance with Section 5.3
      (independent of any termination of your Account or termination of this
      Agreement by us).</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >10.4 <b style="mso-bidi-font-weight: normal;">Effect of Termination</b>.
      Termination of the Agreement, your Account, or of your access to or use of
      any Services may include removal of access to the applicable Services and
      barring of further use of such Services. Termination of this Agreement or
      your Account also includes disassociation or deletion of your username,
      your password and, all related information, files and User Content
      associated with or inside your Account (or any part thereof), including
      your User Content.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Upon termination of the Agreement, your right to use the Services,
      including Mobile Software will automatically terminate. Unboxed will not
      have any liability whatsoever to you for any suspension or termination,
      including for deletion of your User Content.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >All provisions of the Agreement which by their nature should survive,
      shall survive termination of this Agreement, including without limitation,
      ownership provisions, warranty disclaimers, arbitration and governing law,
      and limitations of liability.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
    11. ARBITRATION AND GOVERNING LAW.
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Please read the following arbitration agreement in this Section
      (“Arbitration Agreement”) carefully. It requires you to arbitrate disputes
      with Unboxed and limits the manner in which you can seek relief from
      us.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.1
      <b style="mso-bidi-font-weight: normal;"
        >Applicability of Arbitration Agreement</b
      >. You agree that any dispute, claim, or request for relief relating in
      any way to your access or use of the Services, to any products sold or
      distributed through the Services, or to any aspect of your relationship
      with Unboxed, our Insurer or its Designee, our partners or affiliates will be resolved by binding arbitration, rather than in
      court, except that: (a) you may assert claims or seek relief against Unboxed in small
      claims court if your claims qualify; and (b) you or Unboxed may seek
      equitable relief in court for infringement or other misuse of intellectual
      property rights (such as trademarks, trade dress, domain names, trade
      secrets, copyrights, and patents) or (c) you or our Insurer or its Designee may choose to seek relief as provided in the Insurance Policy (as defined in section 12 below). This Arbitration Agreement shall apply,
      without limitation, to all disputes or claims and requests for relief that
      arose or were asserted before the effective date of this Agreement or any
      prior version of this Agreement.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.2
      <b style="mso-bidi-font-weight: normal;">Arbitration Rules and Forum</b
      >.</span
    ><span style="mso-spacerun: yes;"> </span>Any arbitration between you and
    Unboxed, our Insurer or its Designee, as the case may be, as provided in this section 11 will be governed by the Consumer Arbitration Rules (“AAA Rules”) of
    the American Arbitration Association (“AAA”) then in effect.<span
      style="mso-spacerun: yes;"
    >
    </span
    >In the event of a conflict between this Agreement and the AAA Rules, this
    Agreement will prevail.<span style="mso-spacerun: yes;"> </span>
  </p>



  <p>
    You and Unboxed, our Insurer and its Designee agree that the Federal Arbitration Act applies and governs
    the interpretation and enforcement of this provision (despite the choice of
    law provision in this Agreement).<span style="mso-spacerun: yes;"> </span
    >The AAA Rules, as well as instructions on how to file an arbitration
    proceeding with the AAA, appear at adr.org.
  </p>



  <p>
    For any dispute involving $25,000 or less, any hearing will be take place
    via telephone, unless the arbitrator finds good cause to hold an in-person
    hearing.<span style="mso-spacerun: yes;"> </span>Any in-person hearing will
    take place our principal place of business.<span style="mso-spacerun: yes;">
    </span>
  </p>



  <p>
    <span style="background: white; mso-highlight: white;"
      >11.3
      <b style="mso-bidi-font-weight: normal;">Authority of Arbitrator</b>. The
      arbitrator shall have exclusive authority to: (a) determine the scope and
      enforceability of this Arbitration Agreement; and (b) resolve any dispute
      related to the interpretation, applicability, enforceability, or formation
      of this Arbitration Agreement including, but not limited to, any assertion
      that all or any part of this Arbitration Agreement is void or voidable.
      The arbitration will decide the rights and liabilities, if any, of you and
      Unboxed. The arbitration proceeding will not be consolidated with any
      other matters or joined with any other cases or parties. The arbitrator
      shall have the authority to grant motions dispositive of all or part of
      any claim. The arbitrator shall have the authority to award monetary
      damages and to grant any non-monetary remedy or relief available to an
      individual under applicable law, the arbitral forum’s rules, and the
      Agreement (including the Arbitration Agreement). The arbitrator shall
      issue a written award and statement of decision describing the essential
      findings and conclusions on which the award is based, including the
      calculation of any damages awarded. The arbitrator has the same authority
      to award relief on an individual basis that a judge in a court of law
      would have. The award of the arbitrator is final and binding upon you and
      us.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;">11.4 </span
    ><b style="mso-bidi-font-weight: normal;">Fees</b>.
    <span style="mso-spacerun: yes;"> </span>If you file a
    <span class="GramE">claim</span> You are responsible for filing fees as
    mandated by the AAA. You are also responsible for Your attorneys’ fees,
    witness fees, document production fees.<span style="mso-spacerun: yes;">
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.5 <b style="mso-bidi-font-weight: normal;">Waiver of Jury Trial</b>.
      <b
        >YOU AND UNBOXED HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO
        SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY.  As respects the alternative dispute mechanism provided in this section 11,</b
      > You, Unboxed, the Insurer and its Designee  are, to the extent of this section 11, are instead electing that all disputes, claims, or
      requests for relief shall be resolved by arbitration under this
      Arbitration Agreement, except as specified in Section 11.1 above. An
      arbitrator can award on an individual basis the same damages and relief as
      a court and must follow this Agreement as a court would. However, there is
      no judge or jury in arbitration, and court review of an arbitration award
      is subject to very limited review.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.6
      <b style="mso-bidi-font-weight: normal;"
        >Waiver of Class or Other Non-Individualized Relief</b
      >. ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS
      ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON
      A CLASS OR COLLECTIVE BASIS. ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND
      CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
      CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is
      issued stating that applicable law precludes enforcement of any of this
      subsection’s limitations as to a given dispute, claim, or request for
      relief, then such aspect must be severed from the arbitration and brought
      into the State or Federal Courts located in the Commonwealth of Virginia.
      All other disputes, claims, or requests for relief shall be
      arbitrated.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.7
      <b style="mso-bidi-font-weight: normal;">30-Day Right to Opt Out</b>. You
      have the right to opt out of the provisions of this Arbitration Agreement
      by sending written notice of your decision to opt out to<a
        style="color: blue;" href="mailto:contact@unboxed-app.com"
      >
      contact@unboxed-app.com
      </a>
      within 30 days after first becoming subject to this Arbitration
      Agreement. Your notice must include your name and address, your Unboxed
      username (if any), the email address you used to set up your Account (if
      you have one), and an unequivocal statement that you want to opt out of
      this Arbitration Agreement. If you opt out of this Arbitration Agreement,
      all other parts of this Agreement will continue to apply to you. Opting
      out of this Arbitration Agreement has no effect on any other arbitration
      agreements that you may currently have, or may enter in the future, with
      us.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.8 <b style="mso-bidi-font-weight: normal;">Severability</b>. Except as
      provided in subsection 11.5, if any part or parts of this Arbitration
      Agreement are found under the law to be invalid or unenforceable, then
      such specific part or parts shall be of no force and effect and shall be
      severed and the remainder of the Arbitration Agreement shall continue in
      full force and effect.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >11.9 <b style="mso-bidi-font-weight: normal;">Survival of Agreement</b>.
      This Arbitration Agreement will survive the termination of your
      relationship with Unboxed.</span
    >
  </p>

  <h4
    style="
      margin-top: 8pt;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      mso-pagination: widow-orphan;
      page-break-after: auto;
    "
  >
  12. GENERAL
</h4>

<p>
  <span style="background: white; mso-highlight: white;"
    >These insurance-related notices provide additional information relevant to the insurance product as part of some of our Services. In the event and to the extent these notices conflict with any of the more general notices provided elsewhere in these TERMS OF USE, this more specific INSURANCE section shall apply.  In the event and to the extent these notices conflict with federal or state laws or regulations, the latter shall govern.</span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.1 <b style="mso-bidi-font-weight: normal;">General</b>. Certain paid subscription Services may automatically include insurance covering Your collectible sneakers or shoes which you have uploaded to Unboxed and have provided a valuation by Unboxed as part of the Services.  When you agree to become a subscriber of this level of Service, you are automatically agreeing to become an insured under the insurance policy provided as part of the Service. The premium and other costs associated with this insurance (further described below) have been either incorporated into Your paid monthly subscription fee or You have been quoted and accepted an “add on” monthly charge to accept a higher level of insurance coverage (generally applicable to higher levels of coverage.)
    </span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.2 <b style="mso-bidi-font-weight: normal;">Parties</b>. The Insurance Product is administered by World Trade Labs, inc. dba Assurely as the program’s Managing General Agent and Program Administrator. (National Insurance Producer License Number 190044587).  Assurely acts as our Insurer’s Designee. The insurance carrier (“Insurer”) is Global Indemnity Group, Inc. dba Collectibles Insurance Services and operates in all states other than Pennsylvania as United National Insurance Company. (In Pennsylvania, Global Indemnity acts as Penn Patriot Insurance Company.)</span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.3 <b style="mso-bidi-font-weight: normal;">Insurance Policy</b>. In general, the Insurance Policy provides coverage for direct physical loss or damage to Covered Property while such property is located in an insured Storage Facility or covered in Transit.  Direct physical loss or damage includes Robbery, Burglary and most kinds of physical destruction. Storage Facilities can include Collectible Exhibitions of limited duration.  Note that coverage is limited to losses occurring in shipment where the shipper does not require a signature.  Also please note there is limited or no coverage for collectibles stored in a Flood Zone (Zone A or V) or stored in below ground locations (such as basements) less than 6 inches off the floor.  The Insurance Policy is written on a Surplus Lines basis.  For a general description of Surplus lines, click here. The foregoing is a short summary of the terms of the policy and is for educational and informational purposes only.  This section 12.2 does not form part of the Insurance Policy and should not be relied upon for a full understanding of the insurance coverage.  At the time of a claim, coverage will be governed by the terms, conditions and exclusions of the Insurance Policy which You will be given access to view/download as part of Your onboarding process if insurance is part of Your paid subscription. For a specimen copy of the Insurance Policy, contact Assurely at <a href="mailto:admin_unboxed@assurely.com">admin_unboxed@assurely.com</a></span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.4 <b style="mso-bidi-font-weight: normal;">Limits of Coverage</b>. The Insurance Policy will have a maximum limit stated on the Declarations Page and provided to you as part of Your Unboxed onboarding process.  As your collection grows, you may be given an opportunity to increase the limits to better match the overall value of your collection.  However, as of 4/19/22, in no event shall the Insurance Policy provide coverage in excess of $25,000 per pair of sneakers/shoes, or in excess of $50,000 for Your entire collection unless the policy is especially endorsed and the high value stated therein.  This high value policy CANNOT BE BOUND OR ISSUED IMMEDIATELY and is subject to manual review by our Insurer or its Designee.</span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.5 <b style="mso-bidi-font-weight: normal;">Premium and Fees</b>. The premium and fees for the Insurance Policy is subject to change but are, unless you are advised otherwise, part of Your monthly paid subscription Service if your Service includes insurance.  If the value of the collection exceeds a stated value or the coverage limit of the Insurance Policy, you may be given the opportunity to purchase additional limits for a stated additional premium.  However, You will always know (1) if Your paid subscription includes insurance, (2) what the maximum limit of that insurance policy is, (3) if the premium is 100% part of your paid subscription or (4) an additional limit is recommended for you to purchase and at what monthly price.  By way of illustration and not limitation, as of 4/19/22, the premium and fees allocated from Your paid month subscription for the initial threshold policy limit of $5,000 in coverage was approximately $5.63/mo consisting of approximately $4.17 in premium, $1.25 in processing fees and $0.21 cents in state taxes and fees.  In certain states, the approximation may be higher or lower depending upon state taxes and fees. In the event the initial coverage limit is inadequate, you may be given the opportunity to “buy up” for additional insurance at roughly the same premium rate.  The buy-up limits would be in addition to Your monthly subscription cost.</span
  >
</p>

<p>
  <span style="background: white; mso-highlight: white;"
    >12.6 <b style="mso-bidi-font-weight: normal;">Cancellation/Termination</b>. Your insurance is intertwined with your paid subscription Service.  Therefore if your paid subscription service is terminated or canceled pursuant to section 5.3 or 10 above, Your insurance is canceled or terminated at the same time.  There is no ability to maintain Your insurance outside Your paid subscription Service under the Unboxed program.   Immediately, upon termination or cancellation for any reason of the subscription service with Unboxed You are no longer insured.</span
  >
</p>

<h4
style="
  margin-top: 8pt;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  mso-pagination: widow-orphan;
  page-break-after: auto;
"
>
  13. GENERAL
  </h4>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.1 <b style="mso-bidi-font-weight: normal;">Assignment</b>. This
      Agreement, and any rights and licenses granted hereunder, may not be
      transferred or assigned by you, but may be assigned by Unboxed without
      restriction. Any attempted transfer or assignment in violation hereof will
      be null and void.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.2
      <b style="mso-bidi-font-weight: normal;">Changes to the Agreement</b>.  OTHER THAN AS RESPECTS THE INSURANCE POLICY,
      <b
        >PLEASE NOTE THAT THE AGREEMENT IS SUBJECT TO CHANGE BY UNBOXED ON A
        GOING-FORWARD BASIS IN ITS SOLE DISCRETION AT ANY TIME. </b
      >When changes are made, Unboxed will make a new copy of the modified Terms
      of Use and any modified Supplemental Terms available on the Services. Any
      changes to the Agreement will be effective immediately for new Users of
      the Services and will be effective for continuing Users upon the earlier
      of: (a) thirty (30) days after posting notice of such changes on the
      Services for existing Users; (b) thirty (30) days after dispatch of an
      email notice of such changes to you; or (c) you to providing consent to
      the updated Agreement in a specified manner, as applicable. Unless
      otherwise stated, your continued use of the Services constitutes your
      acceptance of such change(s). If you do not agree to any change(s) after
      receiving a notice of such change(s), you may not be permitted to continue
      using the Services.
      <b
        >PLEASE REGULARLY CHECK THE SERVICES TO VIEW THE THEN-CURRENT TERMS.</b
      ></span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.3
      <b style="mso-bidi-font-weight: normal;">Electronic Communications</b>. By
      entering into this Agreement or using the Services, you agree to receive
      communications from us, including via email, text message, calls, and push
      notifications. You agree that texts, calls or prerecorded messages may be
      generated by automatic telephone dialing systems.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >The communications between you and Unboxed may take place via electronic
      means, whether you visit the Services or send Unboxed emails, or whether
      Unboxed posts notices on the Services or communicates with you via email.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >As respects the Insurance, insurance related documents can include,but are not limited to:
      <ul>
        <li>The Insurance Policy and all documents, notices and correspondence related to your insurance policy. This could include ID Cards, applications, amendments, endorsements, illustrations, questionnaires, disclosures, and reports;
        </li>
        <li>Bills, billing notices, payment schedules or any other correspondence related to premium payments;</li>
        <li>Claim notices, disclosures, status letters, forms, and correspondence concerning a claim that arises under your policy or involves You as an insured and/or claimant;</li>
        <li>Legally mandated policies, notices, and disclosures to inform You of insurance related business practices, including notices related to collection, storage, use, and disclosure of Your personal information; and</li>
        <li>Any other documents related to Your insurance transactions with our Insurer or its Designee.</li>
      </ul>
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >For contractual purposes, you: (a) consent to receive communications in an electronic form; and (b) agree that all terms and
      conditions, agreements, notices, disclosures, and other communications
      that Unboxed provides to you electronically satisfy any legal requirement
      that such communications would satisfy if it were to be in writing. The
      foregoing does not affect your statutory rights. Communications from us
      and our affiliated companies may include but are not limited to:
      operational communications concerning your Account or the use of the
      Services, updates concerning new and existing features on the Services,
      communications concerning promotions run by us or out third-party
      partners, and news concerning Unboxed and industry developments. Standard
      text messaging charges applied by your cell phone carrier will apply to
      text messages that we send.
      <b
        >IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU CAN UNSUBSCRIBE FROM
        OUR PROMOTIONAL EMAIL LIST BY FOLLOWING THE UNSUBSCRIBE IN THE
        PROMOTIONAL EMAIL ITSELF. IF YOU WISH TO OPT OUT OF OUR TEXTS, YOU MAY
        TEXT “STOP” FROM THE MOBILE DEVICE RECEIVING THE TEXT MESSAGES. YOU
        ACKNOWLEDGE THAT YOU ARE NOT REQUIRED TO CONSENT TO RECEIVE PROMOTIONAL
        TEXTS OR CALLS AS A CONDITION OF USING THE SERVICES. HOWEVER, YOU
        ACKNOWLEDGE THAT OPTING OUT OF RECEIVING TEXTS MAY IMPACT YOUR USE OF
        CERTAIN FEATURES OF THE SERVICES.</b
    ></span>
  </p>

  <p>
    <b>To withdraw Your consent from the insurance related electronic communications, You must send a SEPARATE email to admin_unboxed@assurely.com with the following subject line "WITHDRAW ELECTRONIC CONSENT." The body of the email must include your name, policy number, the effective date of your withdrawal and whether you want (a) all communications to be in paper form or (b) your insurance policy to be sent to you in paper form.</b>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.4 <b style="mso-bidi-font-weight: normal;">Limitation Period</b>. YOU, UNBOXED, OUR INSURER AND ITS DESIGNEE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
      THE TERMS, THE SERVICES OR THE CONTENT MUST COMMENCE WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
      PERMANENTLY BARRED (EXCEPT AS PROHIBITED BY LAW OR AS CONTRADICTED BY THE EXPRESS TERMS OF THE INSURANCE POLICY).</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.5 <b style="mso-bidi-font-weight: normal;">Exclusive Venue</b>. To the
      extent the parties are permitted under this Agreement to initiate
      litigation in a court, both you and Unboxed agree that all claims and
      disputes arising out of or relating to the Agreement will be litigated
      exclusively in the state or federal courts located in Fairfax County,
      Virginia. (The foregoing shall not apply to disputes with our Insurer or its Designee regarding insurance whose venue selection will instead by governed by the terms of the Insurance Policy.)</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.6 <b style="mso-bidi-font-weight: normal;">Governing Law</b>. THE
      TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY
      AND UNDER THE LAWS OF THE COMMONWEALTH OF VIRGINIA, CONSISTENT WITH THE
      FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT
      PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION. THE UNITED
      NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DOES
      NOT APPLY TO THE AGREEMENT. (The foregoing shall not apply to disputes with our Insurer or its Designee regarding insurance whose governing law selection will instead by governed by the terms of the Insurance Policy.)</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.7 <b style="mso-bidi-font-weight: normal;">Notice</b>. Where Unboxed
      requires that you provide an email address, you are responsible for
      providing Unboxed with your most current email address. In the event that
      the last email address you provided to Unboxed is not valid, or for any
      reason is not capable of delivering to you any notices required/ permitted
      by the Agreement, <span class="SpellE">Unboxed’s</span>, our Insurer’s or its Designee’s, our partners’ or affiliates’ dispatch of the
      email containing such notice will nonetheless constitute effective notice.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.8 <b style="mso-bidi-font-weight: normal;">International Users</b>. The Services are
      controlled and operated from its facilities in the United States. Unboxed
      makes no representations that the Services are appropriate or available
      for use in other locations. Those who access or use the Services from
      other jurisdictions do so at their own volition and are entirely
      responsible for compliance with all applicable United States and local
      laws and regulations, including but not limited to export control, import,
      and trade sanctions regulations.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You may not access or use the Services if you are located or resident in
      a country or territory subject to an embargo by the United States
      government (including, but not limited to, Cuba, Iran, North Korea, Syria,
      or the Crimea Region), or are an individual or entity designated as a
      blocked or prohibited party by the United States government including, but
      not limited to, designation on the Specially Designated National and
      Blocked Persons (“SDN”) List or Foreign Sanctions Evaders List by the
      Office of Foreign Assets Control of the U.S. Treasury Department (“OFAC”),
      or the Entity List, Denied Persons List, or Unverified List by the Bureau
      of Industry and Security of the U.S. Commerce Department (“BIS”).
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >Unless otherwise explicitly stated, all materials found on the Services
      are solely directed to individuals, companies, or other entities located
      in the United States.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.9 <b style="mso-bidi-font-weight: normal;">Export Control</b>. You may not use,
      export, import, or transfer the Services except as authorized by U.S. law,
      the laws of the jurisdiction in which you obtained the Services, and any
      other applicable laws. In particular, but without limitation, the Services
      may not be exported or re-exported: (a) to any countries or territories
      subject to a U.S. government embargo (including, but not limited to, Cuba,
      Iran, North Korea, Syria, and the Crimea Region) (“Embargoed Countries”);
      or (b) to any individual or entity on the SDN List or Foreign Sanctions
      Evaders List maintained by OFAC or the Denied Persons List, Entity List,
      or Unverified List maintained by BIS (collectively, “Prohibited Party
      Lists”). By using the Services, you represent and warrant that: (<span
        class="SpellE"
        >i</span
      >) you are not located in an Embargoed Country; and (ii) you are not
      listed on any Prohibited Party Lists. You also will not use the Services
      for any purpose prohibited by U.S. law, including for the development,
      design, manufacture or production of missile, nuclear, chemical or
      biological weapons. You acknowledge and agree that products, services, or
      technology provided by Unboxed are subject to the export control and trade
      sanctions laws and regulations of the United States. You shall comply with
      these laws and regulations and shall not, without prior U.S. government
      authorization, export, re-export, or transfer Unboxed products, services,
      or technology, either directly or indirectly, to any country or person in
      violation of such laws and regulations.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.10
      <b style="mso-bidi-font-weight: normal;">Entire Agreement/Severability</b
      >. This Agreement, together with any amendments and any additional
      agreements you may enter into with Unboxed in connection with the
      Services, will constitute the entire agreement between you and Unboxed
      concerning the Services.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >If any provision of this Agreement is deemed invalid by a court of
      competent jurisdiction, the invalidity of such provision will not affect
      the validity of the remaining provisions of this Agreement, which will
      remain in full force and effect. Headings are included for convenience
      only and will not be considered in interpreting this Agreement. For the
      avoidance of doubt, as used in this Agreement, the word including means
      including but not limited to.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.11 <b style="mso-bidi-font-weight: normal;">No Waiver</b>. No waiver
      of any term of this Agreement will be deemed a further or continuing
      waiver of such term or any other term, and
      <span class="SpellE">Unboxed’s</span> failure to assert any right or
      provision under this Agreement will not constitute a waiver of such right
      or provision.</span
    >
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >13.12 <b style="mso-bidi-font-weight: normal;">App Stores</b>. With
      respect to any Mobile Software accessed through or downloaded from the
      Apple App Store (an “App Store Sourced Software”), you will only use the
      App Store Sourced Software (a) on an Apple-branded product that runs the
      iOS (Apple’s proprietary operating system) and (b) as permitted by the
      “Usage Rules” set forth in the Apple App Store.<span
        style="mso-spacerun: yes;"
      >
      </span
      >Notwithstanding the first sentence in this section, with respect to any
      Mobile Software access through or downloaded from the Google Play store (a
      “Google Play Sourced Application”), you may have additional license rights
      with respect to use of the Mobile Software on a shared basis within your
      designated family group.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You agree to comply with all applicable United States and foreign laws
      related to use of the Mobile Software and the Services.
    </span>
  </p>

  <p>
    <span style="background: white; mso-highlight: white;"
      >You acknowledge and agree that the availability of the Mobile Software
      and the Services are dependent on the third party from whom you received
      the Mobile Software license, e.g., the Apple App Store or Google Play
      (each, an “App Store”).
    </span>
  </p>

  <p>
    You acknowledge and agree that this Agreement is solely between you and
    Unboxed, not the App Store and that the App Store has no responsibility for
    the App Store Sourced Software or content thereof.
  </p>



  <p>
    Your use of the App Store Sourced Software must comply with the App Store
    Terms and Conditions.
  </p>



  <p>
    You acknowledge that the App Store has no obligation whatsoever to furnish
    any maintenance and support services with respect to the App Store Sourced
    Software.
  </p>



  <p>
    You acknowledge that, in the event of any third party claim that the App
    Store Sourced Software or your possession and use of that App Store Sourced
    Software infringes that third party’s intellectual property rights, Unboxed,
    not the App Store, will be solely responsible for the investigation,
    defense, settlement and discharge of any such intellectual property
    infringement claim to the extent required by this Agreement.
  </p>



  <p>
    You acknowledge and agree that the App Store and its subsidiaries are third
    party beneficiaries of this Agreement as relates to your license of the App
    Store Sourced Software.<span style="mso-spacerun: yes;"> </span>
  </p>



  <p>
    You acknowledge and agree that, upon your acceptance of the terms and
    conditions of this Agreement, the App Store will have the right (and will be
    deemed to have accepted the right) to enforce this Agreement as relates to
    your license of the App Store Sourced Software against you as a third-party
    beneficiary thereof.
  </p>

  <p>
    For purchases from Apple, Inc.’s App Store only, in the event of any failure
    of the App Store Sourced Software to conform to any applicable warranty, you
    may notify Apple, and Apple will refund the purchase price for the App Store
    Sourced Software to you; to the maximum extent permitted by applicable law,
    Apple will have no other warranty obligation whatsoever with respect to the
    App Store Sourced Software, and any other claims, losses, liabilities,
    damages, costs or expenses attributable to any failure to conform to any
    warranty will be solely governed by this Agreement and any law applicable to
    Unboxed as provider of the software.
  </p>



  <p>
    For purchases from App Stores other than from Apple, Inc., in the event of
    any failure of the App Store Sourced Software to conform to any applicable
    warranty, your right to obtain a refund is governed by the terms of purchase
    from the App Store.<span style="mso-spacerun: yes;"> </span>The App Store
    will have no warranty obligation whatsoever with respect to the App Store
    Sourced Software.<span style="mso-spacerun: yes;"> </span>All claims,
    losses, liabilities, damages, costs or expenses attributable to any failure
    to conform to any warranty will be solely governed by this Agreement and any
    law applicable to Unboxed as provider of the software.
  </p>



  <p>
    14.1 <b style="mso-bidi-font-weight: normal;">Feedback</b>. If you have
    questions or comments about this policy, please contact us at:
    <a href="mailto:contact@unboxed-app.com">contact@unboxed-app.com</a>  If you have questions or comments about the insurance, please contact Assurely at: <a href="mailto:admin_unboxed@assurely.com">admin_unboxed@assurely.com</a>.

  </p>
</div>
