
<div class="mt-5 container">

<p style='text-align:center'><span>PRIVACY
  POLICY</span></p>

  <p><span>Unboxed, LLC (“Unboxed,” “we,” or “us”)
  provides this Privacy Policy to explain our practices regarding the collection,
  use, and disclosure of information that applies to our website and mobile app
  (collectively, our “Service”), as well as your choices regarding the collection
  and use of information. This Privacy Policy applies only to the practices of
  companies we own, control, or are united with under common control. By
  accessing or using our Service, you signify that you have read, understood and
  agree to our collection, storage, use and disclosure of your personal
  information as described in this Privacy Policy.</span></p>

  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_c5dzmram0rbz"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%;color:black'>1.
  HOW WE COLLECT AND USE YOUR INFORMATION</span></h4>

  <p style='margin-top:4.0pt;margin-right:0in;margin-bottom:8.0pt;
  margin-left:0in;line-height:150%'><span>We collect the following
  types of information about you:</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Information
  you provide us directly</span></b><span> — We ask for certain
  information such as your name and e-mail address if you register for a User
  account with the Service, or if you correspond with us (in which case we will
  also retain our responses). If you purchase products or services or sign up for
  a paid subscription, then we will also ask for additional information, such as
  your credit card number and billing address. We may also retain any messages
  you send through the Service, and may collect information you provide in User
  Content you create, share, or post to the Service, which may include
  information contained in your profile as well as photos or other image files
  and associated metadata. If you sign up for our e-mail mailing list, we will
  collect your name and e-mail address. We use this information to operate,
  maintain, and provide to you the features and functionality of the Service.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Information
  we may receive from third parties</span></b><span> — We may receive
  information about you from third parties. For example, if you access our
  websites or Service through a third-party connection or log-in, for example,
  through Facebook Connect, by “following,” “liking,” adding the Unboxed
  application, linking your account to the Unboxed Service, etc., that third
  party may pass certain information about your use of its service to Unboxed.
  This information could include, but is not limited to any information that you
  have permitted the third party to share with us, and any information you have
  made public in connection with that service. We may use this information to
  find your contacts on the Service, to let you know what your contacts are doing
  on the Service, and to let your contacts know what you are doing on the
  Service. We may also use it to suggest additional users or friends you may want
  to follow or connect with. You should always review, and if necessary, adjust
  your privacy settings on third-party websites and services before linking or
  connecting them to Unboxed’s websites or Service. You may also unlink your
  third party account from the Service by adjusting your settings on the third
  party service.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Inviting
  a third party to use our Service</span></b><span> — If you choose to
  use our invitation service to invite a third party to the Service, we will ask
  you for that person’s email address or phone number, or with your permission we
  may access and store your contact list available on your mobile device, and we
  will automatically send an email invitation to the invitee. After sending the
  invitation, we may also send reminder emails to your invitees on your behalf. We
  store this information to send this email, to register the invitee if your
  invitation is accepted, to track the success of our invitation service, and to
  let you know when your contacts join the Service. Unless the invitee becomes a
  registered User of the Service, we do not use the invitee’s email address for
  marketing purposes, and we do not share the invitee’s email address with third
  parties other than Unboxed’s service providers. The invitee may contact us at
  any time to request that we remove his or her information from our database in
  this <a style='color:blue' href="https://forms.gle/Hwq37NiXh3NhpSpX6">form</a>.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Metadata</span></b><span
> — We may collect metadata associated with User Content. Metadata
  typically consists of how, when, where and by whom a piece of User Content was
  collected and how that content has been formatted. Users can add or may have
  added metadata added to their User Content including keywords posted with a
  photo or video, including names, geographical or location information, comments
  or other data. This makes your data more searchable and more interactive. However,
  the metadata may be accessible to others if you share the User Content with
  others or on third party social media sites.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>We use this information to operate,
  maintain, and provide to you the features and functionality of the Service. We
  may also use this information to communicate directly with you. We may send you
  emails containing newsletters, promotions and special offers. If you do not
  want to receive such email messages, you will be given the option to opt out or
  change your preferences. We also use your information to send you
  Service-related emails (e.g., account verification, purchase and billing
  confirmations and reminders, changes/updates to features of the Service,
  technical and security notices). You may not opt out of Service-related e-mails.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>Use of cookies and other technology to
  collect information. We automatically collect certain types of usage
  information when you visit our website or use our Service.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Analytics
  information</span></b><span> — We may directly collect analytics
  data or use third-party analytics tools to help us measure traffic and usage
  trends for the Service. These tools collect information sent by your browser as
  part of a web page request, including the web pages you visit, your browser
  add-ons, your browser’s width and height, and other information that assists us
  in improving the Service. We may collect and use this analytics information in
  aggregate form such that it cannot reasonably be manipulated to identify any
  particular individual user.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Cookies
  information</span></b><span> — When you visit the Service, we and
  our third party partners may send one or more cookies — a small text file
  containing a string of alphanumeric characters — to your computer that uniquely
  identifies your browser and lets Unboxed help you log in faster and enhance
  your navigation through the site. A cookie may also convey information to us
  about how you use the Service (e.g., the pages you view, the links you click
  and other actions you take on the Service), and allow us or our business
  partners to track your usage of the Service over time. You can reset your web
  browser to refuse all cookies or to indicate when a cookie is being sent.
  However, some features of the Service may not function properly if the ability
  to accept cookies is disabled. </span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Log
  file information</span></b><span> — Log file information is
  automatically reported by your browser each time you access a web page. When
  you use our Service, our servers automatically record certain log file
  information. These server logs may include anonymous information such as your
  web request, Internet Protocol (“IP”) address, browser type, referring / exit
  pages and URLs, number of clicks and how you interact with links on the
  Service, domain names, landing pages, pages viewed, and other such information.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Clear
  gifs/web beacons information</span></b><span> — When you use the
  Service, we may employ clear gifs (also known as web beacons) which are used to
  anonymously track the online usage patterns of our Users. In addition, we may
  also use clear gifs in HTML-based emails sent to our Users to track which
  emails are opened and which links are clicked by recipients. The information is
  allows for more accurate reporting and improvement of the Service.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Device
  identifiers</span></b><span> — When you access the Service by or
  through a mobile device (including but not limited to smart-phones or tablets)
  or computer, we may access, collect, monitor and/or remotely store one or more
  “device identifiers.” Device identifiers are small data files or similar data
  structures stored on or associated with your mobile device, which uniquely
  identify your mobile device. A device identifier may be data stored in
  connection with the device hardware, data stored in connection with the
  device’s operating system or other software, or data sent to the device by
  Unboxed. A device identifier may convey information to us about how you browse
  and use the Service. A device identifier may remain persistently on your
  device, to help you log in faster and enhance your navigation through the
  Service. Some features of the Service may not function properly if use or
  availability of device identifiers is impaired or disabled.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Location
  data</span></b><span> — When you access the Service by or through a
  mobile device, we may access, collect, monitor and/or remotely store “location
  data,” which may include GPS coordinates (e.g. latitude and/or longitude) or
  similar information regarding the location of your mobile device. Location data
  may convey to us information about how you browse and use the Service. Some
  features of the Service, particularly location-based services, may not function
  properly if use or availability of location data is impaired or disabled.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Ads
  on our Service</span></b><span> — We may permit third party online
  advertising networks to collect information about your use of our website over
  time so that they may play or display ads that may be relevant to your
  interests on our Service or on other websites or services. Typically, these
  third-party ad servers or ad networks use cookies and other tracking technology
  to compile information about your browser’s or device’s visits and usage
  patterns on the Service and on other sites around the Web, to send you the ads
  that appear on the Service, to measure the effectiveness of their ads and to
  personalize the advertising content. The Unboxed Privacy Policy does not apply
  to, and we cannot control the activities of, third-party advertisers.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>We use or may use the data collected
  through cookies, log file, device identifiers, location data and clear gifs
  information to: (a) remember information so that you will not have to re-enter
  it during your visit or the next time you visit the site; (b) provide custom,
  personalized content and information, including advertising; (c) to provide and
  monitor the effectiveness of our Service; (d) monitor aggregate metrics such as
  total number of visitors, traffic, and demographic patterns; (e) diagnose or
  fix technology problems; (f) help you efficiently access your information after
  you sign in; and (h) track User Content and Users to the extent necessary to
  comply as a service provider with the Digital Millennium Copyright Act; and (i)
  to provide advertising to your browser or device. Although we do our best to
  honor the privacy preferences of our visitors, we are not able to respond to Do
  Not Track signals from your browser at this time.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span></span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span></span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span></span></p>

  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_95o24axfmpui"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>2. SHARING OF
  YOUR INFORMATION</span></h4>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>We may share your information with
  third parties outside Unboxed and its controlled subsidiaries and affiliates in
  the instances described below. For further information, see the “Your Choices
  Regarding Your Information” section below.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><b style='mso-bidi-font-weight:normal'><span>Who
  we may share your information with</span></b><span> — We may share
  your information with third-party business partners, consultants and service
  providers that perform services on our behalf for the purpose of providing the
  Service to you (e.g., email providers, advertising networks, content or service
  fulfilment, analytics companies, etc.). Those business partners will be given
  limited access to your information that is reasonably necessary to deliver the
  Service. We may also share your information with our business partners who
  offer a service to you jointly with us, for example, when running a
  co-sponsored contest or promotion. From time to time, we may share your
  information with third parties who we think may offer you products or services
  you may enjoy.</span></p>

  <p><b style='mso-bidi-font-weight:normal'><span>Who
  you may choose to share your information with</span></b><span> — Any
  information or User Content that you voluntarily disclose for posting to the
  Service, such as your public profile, photos or images and associated metadata,
  and comments, becomes available to other Unboxed users and to the public, as
  controlled by any applicable privacy settings. If you remove information that
  you posted to the Service, copies may remain viewable in cached and archived
  pages of the Service, or if other Users have copied or saved that information.
  You may also choose to share your information with friends through email or
  various social media sites.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>What
  happens in the event of a change of control</span></b><span> — We
  may buy or sell/divest/transfer the company (including any shares in the
  company), or any combination of its products, services, assets and/or
  businesses. Your information such as customer names and email addresses, User
  Content, and other User information related to the Service will likely be among
  the items transferred in these types of transactions. We may also transfer or
  assign such information in the course of corporate divestitures, mergers,
  acquisitions, bankruptcies, dissolutions or similar transactions or
  proceedings. We may also sell, assign or otherwise transfer such information in
  the course of corporate divestitures, mergers, acquisitions, bankruptcies,
  dissolutions, reorganizations, liquidations, similar transactions or
  proceedings involving all or a portion of the company.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Instances
  where we are required to share your information</span></b><span> —
  Unboxed will disclose your information where required to do so by law or
  subpoena or if we reasonably believe that such action is necessary to (a)
  comply with the law and the reasonable requests of law enforcement; (b) to
  enforce our Terms of Use, or to protect the security or integrity of our
  Service; and/or (c) to exercise or protect the rights, property, or personal
  safety of Unboxed, our Users or others.</span></p>



  <p><span>We may also share information with others
  in an aggregated and anonymous form that does not reasonably identify you directly
  as an individual.</span></p>







  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_dyw3p5y1bt3t"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>3. HOW WE STORE
  AND PROTECT YOUR INFORMATION</span></h4>

  <p><b style='mso-bidi-font-weight:normal'><span>Storage
  and Processing</span></b><span> — Your information collected through
  the Service may be stored and processed in the United States or any other
  country in which Unboxed or its subsidiaries, affiliates or service providers
  maintain facilities. Unboxed may transfer information that we collect about
  you, including personal information, to affiliated entities, or to other third
  parties across borders and from your country or jurisdiction to other countries
  or jurisdictions around the world. If you are located in the European Union or
  other regions with laws governing data collection and use that may differ from
  U.S. law, please note that we may transfer information, including personal
  information, to a country and jurisdiction that does not have the same data
  protection laws as your jurisdiction, and you consent to the transfer of
  information to the U.S. or any other country in which Unboxed or its
  subsidiaries, affiliates or service providers maintain facilities and the use
  and disclosure of information about you as described in this Privacy Policy.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Data
  Retention</span></b><span> -We will retain your information for as
  long as your account is active or as needed to provide you services. You may
  deactivate your account in your profile settings. Please <a
  style='color:blue' href="mailto:contact@unboxed-app.com">contact us</a> if you wish to delete your account
  permanently from our systems. Please be aware that we will not be able to
  delete any content you have shared with others or with social media sites.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Deleting
  or Anonymizing Your Content</span></b><span> - If you are under 18
  years of age, you may request that the content that you posted to the Service
  be deleted or anonymized such that your personal information will not be
  identifiable publicly on our Service by <a href="mailto:contact@unboxed-app.com">contacting us</a>.
  While we will use all commercially reasonable efforts to delete or anonymize
  your user content upon request, please be aware that due to the social nature
  of our Service, you may not be able to completely remove all of your personally
  identifiable user content if, for example, that content has been stored,
  republished, or reposted by another user or a third party. We may also maintain
  your information in identifiable form for our internal use, even if your
  personal data is no longer visible to the public on our Service.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Keeping
  your information safe</span></b><span> — Unboxed cares about the
  security of your information, and uses commercially reasonable safeguards to
  preserve the integrity and security of all information collected through the
  Service. However, Unboxed cannot ensure or warrant the security of any
  information you transmit to Unboxed or guarantee that information on the
  Service may not be accessed, disclosed, altered, or destroyed. Your privacy
  settings may also be affected by changes to the functionality of Unboxed’s
  distributors, such as social networks. Unboxed is not responsible for the
  functionality or security measures of any third party.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Compromise
  of information</span></b><span> — In the event that any information
  under our control is compromised as a result of a breach of security, Unboxed
  will take reasonable steps to investigate the situation and where appropriate,
  notify those individuals whose information may have been compromised and take
  other steps, in accordance with any applicable laws and regulations.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_uyglqckouztv"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'></span></h4>

  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><span
  style='mso-bidi-font-size:11.0pt;line-height:115%'>4. YOUR CHOICES ABOUT YOUR
  INFORMATION</span></h4>

  <p><b style='mso-bidi-font-weight:normal'><span>You
  control your account information and settings</span></b><span> — You
  may update your account information, profile information and
  email-communication preferences at any time by logging in to your account and
  changing your profile settings. You can also stop receiving promotional email
  communications from us by clicking on the “unsubscribe link” provided in such
  communications. As noted above, you may not opt out of Service-related
  communications (e.g., account verification, purchase and billing confirmations
  and reminders, changes/updates to features of the Service, technical and
  security notices). If you have any questions about reviewing or modifying your
  account information, you can <a href="mailto:contact@unboxed-app.com">contact us</a>.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_afgninf2ansk"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>5. CHILDREN’S
  PRIVACY</span></h4>

  <p><span>Unboxed does not knowingly collect or
  solicit personal information from anyone under the age of 13. The Service and
  its content are not directed at children under the age of 13. In the event that
  we learn that we have inadvertently collected personal information from a child
  under age 13, we will delete that information as quickly as possible. If you
  believe that we might have any information from or about a child under 13,
  please <a href="mailto:contact@unboxed-app.com">contact us</a>.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_j4ausqjm2r8g"></a><span
 style='font-size:11.0pt;line-height:115%'>6</span><span
  style='mso-bidi-font-size:11.0pt;line-height:115%'>. LINKS TO OTHER WEBSITES
  AND SERVICES</span><span style='font-size:11.0pt;line-height:115%'></span></h4>

  <p><span>We are not responsible for the practices
  employed by websites or services linked to or from the Service, including the
  information or content contained therein. Please remember that when you use a
  link to go from the Service to another website, our Privacy Policy does not
  apply to third-party websites or services. Your browsing and interaction on any
  third-party website or service, including those that have a link on our
  website, are subject to that third party’s own rules and policies. In addition,
  you agree that we are not responsible and we do not exercise control over any
  third-parties that you authorize to access your User Content. If you are using
  a third party website or service (like Facebook or Twitter) and you allow such
  a third party access to your User Content, you do so at your own risk. This
  Privacy Policy does not apply to information we collect by other means
  (including offline) and from other sources other than through the Service.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_egfpiis5125k"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>7. HOW TO CONTACT
  US</span></h4>



  <p><span>If you have any questions about this
  Privacy Policy or the Service, please <a href="mailto:contact@unboxed-app.com">contact us</a>.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_563x1ix2vds1"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>8. CHANGES TO OUR
  PRIVACY POLICY</span></h4>

  <p><span>Unboxed may, in its sole discretion, modify
  or update this Privacy Policy from time to time, and so you should review this
  page periodically. When we change the policy in a material manner, we will
  update the ‘last modified’ date at the bottom of this page.</span></p>



  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_40vbbltjyywi"></a><span
 style='mso-bidi-font-size:11.0pt;line-height:115%'>9. EU ADDENDUM</span></h4>

  <p><b style='mso-bidi-font-weight:normal'><span>Controller</span></b><span
>. Visual Supply Company is the controller of the personal data that
  we have about you and can be reached <a href="mailto:contact@unboxed-app.com">through email</a>.
  </span></p>



  <p><span>Your rights. You may ask us to take the
  following actions:</span></p>

  <p style='margin-top:8.0pt;margin-right:0in;margin-bottom:0in;
  margin-left:47.0pt;margin-bottom:.0001pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Access</span></u><span
>. Provide you with information about our processing of your personal
  data and give you access to it.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Correct</span></u><span
>. Update or correct inaccuracies in your personal data.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Delete</span></u><span
>. Delete your personal data.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Export</span></u><span
>. Export a machine-readable copy of your personal data that you can
  use with a different service.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Restrict</span></u><span
>. Restrict the processing of your personal data.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l3 level1 lfo2'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Object</span></u><span
>. Object to our reliance on our legitimate interests as the legal basis
  of processing your personal data if that processing adversely impacts your
  legal rights.</span></p>



  <p><span>To request one of these actions, <a href="mailto:contact@unboxed-app.com"
  style='color:blue'>contact us</a>. We may request information from you to
  help us confirm your identity and process your request. Applicable law may require
  or permit us to decline your request. If we decline your request, we will tell
  you why, subject to legal restrictions.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Marketing
  emails</span></b><span>. You can always opt out of our marketing
  emails by following the unsubscribe prompt in the message. You may still
  receive administrative or other non-marketing messages.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Legal
  bases</span></b><span>. We generally process your personal data on
  the following legal bases:</span></p>

  <p style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
  margin-left:.65in;text-indent:-.25in;line-height:normal;mso-list:l2 level1 lfo3'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Contractual necessity</span></u><span>. You have entered
  a contract with us and we need to use some of your personal data to provide
  services you have requested or take steps that you request prior to providing
  services. For example, we cannot create an account for you if you do not
  provide required information like your email address or phone number. If you do
  not provide information indicated as required or mandatory within our service,
  or that is otherwise necessary to provide a requested service or feature within
  the service, it will not be available to you.</span></p>

  <p style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
  margin-left:.65in;text-indent:-.25in;line-height:normal;mso-list:l2 level1 lfo3'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Compliance with law</span></u><span>. We may have to
  process or share your personal data to comply with legal obligations, such as
  to comply with a subpoena or legal process.</span></p>

  <p style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
  margin-left:.65in;text-indent:-.25in;line-height:normal;mso-list:l2 level1 lfo3'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Legitimate interests</span></u><span>. We process your
  personal data for purposes that constitute our legitimate interests, such as
  fraud prevention and safety; protecting our, your or others’ property rights;
  exercising or defending legal claims; investigating violations of and enforcing
  our Terms of Use; analyzing and improving our services; and marketing and
  advertising.</span></p>

  <p style='margin-left:47.0pt;text-indent:-.25in;mso-list:l2 level1 lfo3'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Consent</span></u><span
>. In some cases, we may process your personal data based on your
  consent, such as where we request access to data on your mobile device through
  the prompts in your device’s operating system. Where we rely on your consent
  you have the right to withdraw it anytime in the manner indicated at the time
  consent is requested.</span></p>









  <h4 style='margin-top:8.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:
  0in;mso-pagination:widow-orphan;page-break-after:auto'><a name="_xlnggusfwj1f"></a><a
  name="_yw59hjorxmmy"></a><span style='mso-bidi-font-size:11.0pt;
  line-height:115%'>10. IMPORTANT INFORMATION FOR CALIFORNIA RESIDENTS</span><span
 style='font-size:11.0pt;line-height:115%'></span></h4>

  <p><b style='mso-bidi-font-weight:normal'><span>Scope</span></b><span
>. This addendum supplements our Privacy Policy and applies only to
  California residents and the Personal Information we collect about them as a
  “business,” as defined under the California Consumer Privacy Act of 2018
  (“CCPA”). For purposes of this section, “Personal Information” has the meaning
  given in the CCPA but does not include information exempted from the scope of
  the CCPA. Additionally, this section does not apply to information we collect
  from you in the course of communicating with you in your capacity as an
  employee, controlling owner, director, officer or contractor of an organization
  (i.e., company, partnership, sole proprietorship, non-profit or government
  agency) while we are providing or receiving products or services to or from, or
  performing due diligence on, that organization.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>Collection,
  use and disclosure of your Personal Information</span></b><span>. We
  describe the categories and sources of Personal Information we collect and the business/commercial
  purposes for which we use it in Section 1 of our Privacy Policy (How We Collect
  and Use Your Information). We describe the categories of third parties to whom
  we disclose your Personal Information in Section 2 of our Privacy Policy (Sharing
  of Your Information). These summaries describe our practices currently and
  during the 12 months preceding the effective date of this addendum.</span></p>



  <p><b style='mso-bidi-font-weight:normal'><span>California
  ‘Do Not Sell My Info’ Notice</span></b><span>. Like many companies
  online, we use advertising services that use information collected from cookies
  and similar technologies to try to make the ads you see on other websites more
  relevant to your interests. This is called interest-based advertising. See the
  section of our Privacy Policy entitled User of Cookies and other Technology to
  Collect Information for details. Although we do not sell your information to
  these companies for money, our use of these services may constitute a “sale” of
  Personal Information from which you have the right to opt-out for purposes of
  the CCPA. You can opt-out of the use of your information for interest-based
  advertising by:</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:49.6pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo1'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Browser settings</span></u><span>. Blocking third party
  cookies in your web browser settings.</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:49.6pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo1'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Privacy browsers/plug-ins</span></u><span>. Using privacy
  browsers or ad-blocking browser plug-ins that let you block advertising
  trackers.</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:49.6pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo1'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Ad industry tools</span></u><span>. Opting-out of
  interest-based ads from companies participating in the following industry
  opt-out programs:</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:70.85pt;text-indent:-.25in;line-height:normal;mso-list:l0 level2 lfo1'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>Network Advertising Initiative: <a
  href="https://www.networkadvertising.org/managing/opt_out.aspx"><span
  style='color:#AE995A;text-decoration:none;text-underline:none'>https://www.networkadvertising.org/managing/opt_out.aspx</span></a></span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:70.85pt;text-indent:-.25in;line-height:normal;mso-list:l0 level2 lfo1'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>Digital Advertising Alliance:</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:92.1pt;text-indent:-.25in;line-height:normal;mso-list:l0 level3 lfo1'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>■<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>Opt-out (<a href="https://optout.aboutads.info/"><span
  style='color:#AE995A;text-decoration:none;text-underline:none'>optout.aboutads.info</span></a>),
  which will allow you to opt-out of interest based ads served by on websites by
  participating members.</span></p>

  <p style='margin-top:0in;margin-right:59.75pt;margin-bottom:
  6.0pt;margin-left:92.1pt;text-indent:-.25in;line-height:normal;mso-list:l0 level3 lfo1'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>■<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>AppChoices mobile app (available at <a
  href="https://www.youradchoices.com/appchoices"><span style='color:#AE995A;
  text-decoration:none;text-underline:none'>https://www.youradchoices.com/appchoices</span></a>),
  which will allow you to opt-out of interest-based ads in mobile apps served by
  participating members.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  8.0pt;margin-left:49.6pt;text-indent:-.25in;mso-list:l0 level1 lfo1'><span
 style='font-size:10.5pt;line-height:115%;color:black'><span
  style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><u><span>Mobile settings</span></u><span
>. Using your mobile device settings to limit use of the advertising
  ID associated with your mobile device for interest-based advertising purposes.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>We recommend that you take all of these
  steps if you want to prevent collection of your information for interest-based
  ads. You will need to apply these opt-out settings on each device from which
  you wish to opt-out. Not all companies that serve interest-based ads participate
  in these opt-out programs, so even after opting-out, you may still receive
  cookies and interest-based ads from other companies. If you opt-out, you will
  still see advertisements online but they may be less relevant to you.</span></p>

  <p style='margin-top:0in;margin-right:-2.0pt;margin-bottom:
  6.0pt;margin-left:0in;line-height:normal'><span>Your right to
  information, access and deletion. You have the following rights under the CCPA:</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:21.25pt;text-indent:-.25in;line-height:normal;mso-list:l1 level1 lfo4'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Information</span></u><span>. You can request the
  following information about how we have collected and used your Personal
  Information during the past 12 months:</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The categories of Personal Information that we have collected.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The categories of sources from which we collected the Personal
  Information.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The categories of third parties with whom we share the Personal
  Information.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The categories of Personal Information that we sold or disclosed for
  a business purpose.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The categories of third parties to whom the Personal Information was
  sold or disclosed for a business purpose.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:42.5pt;text-indent:-.25in;line-height:normal;mso-list:l1 level2 lfo4'><span
 style='font-size:10.5pt;color:black'><span style='mso-list:Ignore'>○<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
>The business or commercial purpose for collecting and/or selling
  Personal Information.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:21.25pt;text-indent:-.25in;line-height:normal;mso-list:l1 level1 lfo4'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Access</span></u><span>. You can request a copy of the
  Personal Information that we have collected about you during the past 12
  months.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:21.25pt;text-indent:-.25in;line-height:normal;mso-list:l1 level1 lfo4'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Deletion</span></u><span>. You can ask us to delete the
  Personal Information that we have collected from you.</span></p>

  <p style='margin-top:0in;margin-right:60.0pt;margin-bottom:
  6.0pt;margin-left:21.25pt;text-indent:-.25in;line-height:normal;mso-list:l1 level1 lfo4'><span
 style='font-size:10.5pt;color:black'></span><u><span
>Non-discrimination</span></u><span>. You are entitled to
  exercise the rights described above free from discriminatory treatment
  prohibited by the CCPA.</span></p>

  <p style='margin-top:8.0pt;margin-right:-2.0pt;margin-bottom:
  8.0pt;margin-left:0in'><span>You may submit a request to exercise
  your right to information, access or deletion by contacting us. We reserve the
  right to confirm your California residency to process these requests and will
  need to confirm your identity. Government-issued identification may be
  required. You may designate an authorized agent to make a request on your
  behalf by providing a valid power of attorney or other proof of authority
  acceptable to us in our reasonable discretion. Government-issued identification
  may be required for both the requester and the authorized agent. We cannot
  process your request if you do not provide us with sufficient information to
  allow us to understand and respond to it. As email is not a secure communication
  channel, you should not email government-issued identification to us. In
  certain cases we may decline your request as permitted by law.</span></p>



  <p><span>Questions. If you have any questions or
  concerns, please <a href="mailto:contact@unboxed-app.com">contact us</a>. </span></p>



  <p><span>12. IMPORTANT INFORMATION FOR NEVADA RESIDENTS.</span></p>



  <p><span>If you are a resident of Nevada, you may request
  we limit the sale of certain personal information to third parties for resale
  or licensing purposes.<span style='mso-spacerun:yes'>  </span>You are entitled
  to register your preference for limits on such sales in the future by
  contacting us.<span style='mso-spacerun:yes'>  </span>Please <a
  style='color:blue' href="mailto:contact@unboxed-app.com">contact us</a> via e-mail with the subject line,
  &quot;Nevada Do Not Sell Request&quot;. You must include your first and last name, zip code, the e-mail address
  connected to your account, or, if you have terminated your account, then the
  e-mail address connected to your terminated account. We reserve the right to require further
  information in order to verify you are a Nevada resident.</span></p>

</div>
