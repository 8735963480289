<div
  class="main text-center d-flex flex-column justify-content-center align-items-center mt-50"
>
  <div class="alert alert-success" role="alert" *ngIf="success.hasSuccess">
    {{ success.message }}
  </div>

  <div *ngIf="!success.hasSuccess">
    <p class="mb-4">
      Please enter your new account password below.
    </p>

    <div class="alert alert-warning" role="alert" *ngIf="error.hasError">
      {{ error.message }}
    </div>

    <form
      class="reset-form"
      class="mb-4 mr-4 ml-4"
      [formGroup]="resetForm"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field class="reset-full-width">
        <mat-label>NEW PASSWORD</mat-label>
        <input
          type="password"
          matInput
          placeholder="NEW PASSWORD"
          formControlName="newPassword"
        />
        <mat-error *ngIf="newPassword.hasError('minlength') && !newPassword.hasError('minlength')">
          Must be a min of 8 characters.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="reset-full-width">
        <mat-label>CONFIRM PASSWORD</mat-label>
        <input
          type="password"
          matInput
          placeholder="CONFIRM PASSWORD"
          formControlName="confirmPassword"
        />
        <mat-error *ngIf="newPassword.hasError('minlength') && !newPassword.hasError('minlength')">
          Must be a min of 8 characters.
        </mat-error>
      </mat-form-field>


      <button
        type="submit"
        class="btn btn-primary mt-75"
        [disabled]="!resetForm.valid"
      >
        Reset Password
      </button>
    </form>
  </div>
</div>
