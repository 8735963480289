import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  verify(accessToken) {
    return this.http.get(`${environment.api}/user/verify?verifyToken=${accessToken}`);
  }

  reset(params, token) {
    return this.http.post(`${environment.api}/user/reset`, params);
  }
}
